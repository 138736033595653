import React from 'react';

import cn from 'classnames';
import Image from 'next/legacy/image'

import I18n from '@utils/i18n';

import styles from './index.module.less';

export const DefaultLeftSide: React.FC<any> = () => {
  return (
    <div className={styles.leftSide}>
      <div className={styles.logoContainer}>
        <p className={styles.logoText}>{I18n.t('Y9i2wmTA')}</p>
        <Image
          alt=""
          src={require('@images/landingPage.webp')}
          width={347}
          height={292}
          quality={100}
        />
      </div>
      <div className={styles.downloadContainer}>
        <Image
          alt=""
          src={require('@images/login/qrcode.webp')}
          layout="fixed"
          width={62}
          height={62}
          quality={100}
        />
        <p className={cn(styles.downloadText, 'DINPro')}>
          {I18n.t('common_scan_download')}
          <br />
          <span className={styles.downloadSmallText}>
            {I18n.t('start_crypto_trading')}
          </span>
        </p>
      </div>
    </div>
  );
}
